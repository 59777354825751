<template>
  <a-spin :spinning="spinning" size="large" tip="加载中" style="min-height: 650px">
    <div v-show="!spinning">
      <!-- 广告 -->
      <div class="header-body">
        <!-- 一级菜单 -->
        <div class="menu" @mouseleave="showMenu = false">
          <div class="menu-left">
            <!-- <div @click="toUser" class="avatar-block">
              <h3 class="gray">欢迎您</h3>
              <h2 class="theme">{{ user.name || '- -' }}</h2>
            </div>-->
            <div
              class="menu-block"
              v-for="c1 in category"
              :key="c1.id_product_category"
              @mouseover="selectMenu(c1.id_product_category)"
              @click="to_c1(c1.id_product_category)"
            >
              <div>{{ c1.name }}</div>
              <!-- <a-icon style="flex:1" class="right"></a-icon> -->
            </div>
          </div>
          <!-- 二级菜单 -->
          <div v-show="showMenu" class="menu-right">
            <span class="category" v-for="c2 in subCategory" :key="c2.id_product_category">
              <span type="flex" class="c2 detail">
                <router-link
                  :to="{ name:'products', query: { category: c2.id_product_category }}"
                  class="detail"
                >{{ c2.name }}</router-link>
              </span>
              <a-row class="c3">
                <div v-for="c3 in c2.children" :key="c3.id_product_category" class="detail">
                  <router-link
                    :to="{ name:'products', query: { category: c3.id_product_category }}"
                  >{{ c3.name }}</router-link>
                </div>
              </a-row>
            </span>
          </div>
        </div>
        <!-- 轮播图 -->
        <a-carousel class="ad" autoplay>
          <img
            style="height: 500px; object-fit: cover;"
            v-for="img in banner"
            :key="img.url"
            :src="img.url"
          />
        </a-carousel>
        <!-- 右边的快捷入口 -->
        <div class="quick">
          <div class="menu-quick">
            <div class="avatar-block">
              <h3 class="gray">欢迎您：</h3>
              <h3 class="theme" v-if="user">{{ user.name }}</h3>
              <h3 class="theme" v-if="!user">{{ '- -' }}</h3>
            </div>
            <div style="display:flex;justify-content:center;">
              <a
                style="margin-right:30px;fontSize:15px"
                @click="$router.push({ name: 'login'})"
              >一键登录</a>
              <a style="fontSize:15px" @click="$router.push({ name: 'register'})">快速注册</a>
            </div>
            <h3 style="margin:10px auto 0 20px">快捷入口</h3>
            <div style="display:flex;flex-wrap:wrap;justify-content:center;margin-top:10px;">
              <div
                class="button-body"
                :key="index"
                @click="$router.push(button.to)"
                v-for="(button, index) in [
                  { name: '待付款', to: { name: 'orders', params: { tab: '2' } }, icon: 'icon-daifukuan', bg: 'linear-gradient(89.9deg, rgb(255, 190, 32) 0.6%, rgb(251, 112, 71) 124.3%)'},
                  { name: '付款待确认', to: { name: 'orders', params: { tab: '2' } }, icon: 'icon-fukuandaiqueren', bg: 'linear-gradient(109.6deg, rgb(120, 212, 234) 11.2%, rgb(206, 216, 253) 82.1%)'},
                  { name: '待发货', to: { name: 'orders', params: { tab: '5' } }, icon: 'icon-daifahuo', bg: 'linear-gradient(109.6deg, rgb(120, 212, 234) 11.2%, rgb(206, 216, 253) 82.1%)'},
                  { name: '待收货', to: { name: 'orders', params: { tab: '5' } }, icon: 'icon-daishouhuo', bg: 'linear-gradient(107deg, rgb(13, 198, 180) 38.1%, rgb(33, 198, 138) 79.5%)'},
                  { name: '待退货', to: { name: 'orders', params: { tab: '9' } }, icon: 'icon-yishouhuo', bg: 'linear-gradient(104.9deg, rgb(255, 95, 162) 2.3%, rgb(254, 201, 154) 92.7%)'},
                  { name: '售后申请', to: { name: 'orders', params: { tab: '9' } }, icon: 'icon-tuihuozhong', bg: 'radial-gradient(100px at 10.4% 22.3%, rgb(245, 235, 238) 0%, rgb(186, 190, 245) 93.6%)'},
                ]"
              >
                <span v-if="index!=5">
                  <div class="circle" :style="'background:'+button.bg">
                    <icon-font :type="button.icon" />
                  </div>
                </span>
                <span v-else>
                  <a-popover>
                    <template slot="content">
                      <h3>如需咨询请发邮件至Jysupply@126.com或致电4001689282</h3>
                    </template>
                    <div class="circle" :style="'background:'+button.bg">
                      <icon-font :type="button.icon" />
                    </div>
                  </a-popover>
                </span>
                <div class="word">{{ button.name }}({{orderCount[index]?orderCount[index]:0}})</div>
                <!-- <div class="word" v-else>{{ button.name }}</div> -->
              </div>
            </div>
            <div style="display:flex">
              <h3 style="margin:10px auto 0 20px">公告栏</h3>
              <a
                style="margin:10px 20px 0 auto;display:flex;align-items:center"
                @click="$router.push({ name: 'announcement'})"
              >
                更多
                <a-icon type="right" />
              </a>
            </div>
            <a
              v-for="(i,index) in announcementList.data"
              :key="index"
              style="display:flex;margin:10px auto 0 20px;"
              class="text"
              @click="$info({
              title: i.title,
              content: i.content,
            })"
            >{{index+1}}.{{i.title}}</a>
          </div>
        </div>
      </div>

      <!-- 各类模块 -->
      <div class="container">
        <div theme="light" v-for="group in productModules" :key="group.id">
          <div class="header">{{ group.id }}</div>
          <a-row class="main">
            <a-col class="card-col" v-for="product in group.list" :key="product.id_product_info">
              <a-card
                class="card"
                :body-style="{ padding: '0px' }"
                @click.native="toProduct(product.id_product_info)"
              >
                <div class="product_img-div">
                  <img v-lazy="product.url ? product.url : 'index/noimg.png'" class="product_img" />
                </div>
                <div style="padding: 14px;">
                  <div class="title">{{ product.name}}</div>
                  <h3 class="price" v-if="isShowPrice">￥{{ product.price_settle }} / {{product.product_unit}}</h3>
                  <h3
                    style="color:red;display:flex;justify-content:center"
                    v-if="!isShowPrice"
                  >登录后查看价格</h3>
                  <div justify="center" align="middle" class="button">
                    <a-button
                      type="primary"
                      :loading="isButtonLoading"
                      @click.stop="buy(product.id_product_info)"
                    >加入购物车</a-button>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { Icon } from 'ant-design-vue'

export default {
  components: {
    IconFont: Icon.createFromIconfontCN({
      scriptUrl: '//at.alicdn.com/t/font_2399434_7eekkae1vh7.js'
    })
  },
  data() {
    return {
      isShowPrice: true,
      isButtonLoading: false, // 是否显示按钮加载
      banner: [{ url: 'index/none.png' }],
      productModules: [
        { id: '热销单品', list: [] },
        { id: '新品上架', list: [] },
        { id: '精品推荐', list: [] }
      ],
      category: [], // 菜单，存储类目
      subCategory: [], // 子菜单，存储第二、第三类目
      announcementList: [], // 存放公告栏
      text: '正在连接商城', // 欢迎文字
      user: {},
      showMenu: false,
      spinning: true,
      orderCount: []
    }
  },
  mounted() {
    if (this.getUser() == null) {
      this.isShowPrice = false
    }
    document.title = '舒悦商城'
    if (this.$route.params.forceStop) return
    if (this.$route.params.showAnnouncement && this.getUser() != null) {
      this.get('getAnnouncementForFranchisee').then((res) => {
        if (res) {
          this.$info({
            title: res.title,
            content: res.content,
            maskClosable: true
          })
        }
      })
    }
    this.user = this.getSession('user')
    if (this.getUser() != null) {
      this.$axios
        .all([
          this.get('getAllAnnouncement', { number: 3 }).then((e) => {
            this.announcementList = e
          }),
          this.get('getOrderCountForFranchisee').then((e) => {
            this.orderCount[0] = e.not_pay
            this.orderCount[1] = e.not_confirmed
            this.orderCount[2] = e.have_paid
            this.orderCount[3] = e.not_receive
            this.orderCount[4] = e.have_receive
            this.orderCount[5] = e.refund
            this.spinning = false
          })
        ])
        .then(() => {
          this.spinning = false
        })
    }
    this.$axios
      .all([
        this.get('getProductBySales', { number: 5 }).then((e) => {
          this.productModules[0].list = e
        }),
        this.get('getProductByTime', { number: 5 }).then((e) => {
          this.productModules[1].list = e
        }),
        this.get('getProductByRecommend', { number: 5 }).then((e) => {
          this.productModules[2].list = e
        }),
        this.get('getAllBanner', { status: 1 }).then((e) => {
          this.banner = e.data
        }),
        this.get('getCategory', { status: 1 }).then((e) => {
          this.category = e
        })
        // this.get('getAllAnnouncement', { number: 3 }).then((e) => {
        //   this.announcementList = e
        // })
      ])
      .then(() => {
        this.spinning = false
      })
  },
  methods: {
    login() {
      this.$router.replace({ name: 'login' })
    },
    toUser() {
      this.$router.push({ name: 'user' })
    },
    selectMenu(id) {
      this.subCategory = this.category.find(
        (e) => e.id_product_category === id
      ).children
      this.showMenu = true
    },
    showbuy(id) {
      // console.log(id)
    },
    toProduct(id) {
      this.$router.push({ name: 'product', query: { id: id } })
    },
    // 一级菜单按钮
    to_c1(id) {
      this.$router.replace({ name: 'index', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: id
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    },
    // 添加购物车
    buy(id) {
      this.isButtonLoading = true
      // 得到货品名称和最低购买量
      for (let i = 0; i < 3; i++) {
        // console.log(this.productModules[i].list.length)
        for (let j = 0; j < this.productModules[i].list.length; j++) {
          if (this.productModules[i].list[j].id_product_info === id) {
            // console.log(this.productModules[i].list[j].num_minimum)
            var amount = this.productModules[i].list[j].num_minimum
            var name = this.productModules[i].list[j].name
          }
        }
      }
      this.post('addCartForFranchisee', {
        id_product_info: id,
        amount: amount
      })
        .then(() => {
          this.isButtonLoading = false
          this.$message.success(`${name}*${amount} 已加入购物车`)
        })
        .catch((err) => {
          this.isButtonLoading = false
          this.$message.error(err.msg)
        })
    }
  }
}
</script>

<style scoped>
.header-body {
  display: flex;
  justify-content: center;
  margin: 20px auto 0 auto;
  max-width: 1600px;
  padding: 0 30px;
}
/* 一级菜单 */
.ad {
  width: 75%;
  height: 500px;
  overflow: hidden;
  z-index: -1;
  position: absolute;
}
.menu {
  display: flex;
  text-align: center;
  height: 500px;
  padding: 0 0 0 20px;
  width: 15%;
  margin-right: auto;
  /* margin: 0 auto; */
  /* max-width: 1200px; */
}
.quick {
  display: flex;
  text-align: center;
  height: 500px;
  padding: 0 0 0 20px;
  width: 18%;
  margin-left: auto;
}
.menu-left {
  box-shadow: 0px 0px 50px -17px rgba(0, 0, 0, 0.64);
  background-color: #fff;
  height: 500px;
  width: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-quick {
  box-shadow: 0px 0px 50px -17px rgba(0, 0, 0, 0.64);
  background-color: #fff;
  height: 500px;
  width: 100%;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* padding-bottom: 200px; */
}
.menu-quick .avatar-block {
  display: flex;
  justify-content: center;
  color: #aaa;
  /* height: 160px; */
  padding: 30px 0 10px 0;
  /* line-height: 30px; */
  /* margin-bottom: 10px; */
  /* border-bottom: #ddd 0.5px solid; */
  cursor: pointer;
}
.menu-left .menu-block {
  padding: 0 10px;
  height: 90px;
  display: flex;
  cursor: pointer;
}
.menu-block div {
  width: 180px;
  align-self: center;
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
}
/* 二级菜单 */
.menu-right {
  box-shadow: 0px 0px 50px -17px rgba(0, 0, 0, 0.64);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: 460px;
  width: 960px;
  min-width: 900px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.category {
  display: flex;
  flex-direction: row;
  border-bottom: #eee dashed 0.5px;
  height: 30px;
}
.category .detail {
  line-height: 30px;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}
.category .c2 {
  min-width: 130px;
  display: flex;
}
.category .c2 > .detail {
  text-align: left;
  font-weight: 450;
}
.category .c3 {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}
.category .c3 > .detail {
  margin-right: 15px;
  color: #666;
}
/* 卡片样式 */
.container {
  margin: 0 auto;
  max-width: 1600px;
}
.header {
  text-align: left;
  margin-top: 40px;
  padding: 0 50px;
  max-height: 30px;
  font-size: 25px;
}
.main {
  margin: 0;
  padding: 20px 50px;
  display: flex;
  flex-wrap: wrap;
}
/* 卡片 */
.card-col {
  width: 250px;
  margin-right: 50px;
  margin-bottom: 30px;
}
/* .card-col:nth-child(5n) {
  margin-right: 0px;
} */
.card {
  cursor: pointer;
  border-radius: 10px;
}
.card:hover {
  /* background-color:rgba(52,127,155,0.5); */
  color: #347f9b;
  font-weight: 600;
  box-shadow: -10px 5px 20px -16px rgba(0, 0, 0, 0.24);
  transform: scale(1.03);
}
.card .button {
  opacity: 0;
}
.card:hover .button {
  opacity: 1;
}

.card:hover .price {
  color: #347f9b;
}
.price {
  line-height: 6px;
  font-size: 13px;
  color: #f00;
}
.title {
  padding-bottom: 10px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}
.product_img-div {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_img {
  max-width: 100%;
  max-height: 250px;
  display: block;
}

/* 引入一级菜单方框动画 */
.menu-block {
  border: none;
  position: relative;
}
.menu-block::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border:1px solid rgba(255,255,255,0.5); */
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.menu-block:hover::before {
  opacity: 0;
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.menu-block::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(52, 127, 155, 0.5);
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.menu-block:hover::after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.quick-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid;
  font-size: 20px;
}
.button-body {
  text-align: center;
  cursor: pointer;
  color: var(--font);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.button-body:hover {
  transform: scale(1.08);
  color: var(--theme);
}
.button-body .circle {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* line-height: 83px; */
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
}
.button-body .word {
  margin: 10px 0 10px;
  font-size: 10px;
  width: 90px;
  display: flex;
  justify-content: center;
}
.text {
  color: #000;
}
.text:hover {
  text-decoration: underline;
}
</style>
